<template>
  <TransitionRoot as="template" :show="$carroVisible">
    <Dialog
      :initialFocus="completeButtonRef"
      as="div"
      class="relative z-30"
      @close="CerrarCarro"
    >
      <TransitionChild
        as="template"
        enter="ease-in-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in-out duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div
            class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pt-14 md:pl-10 md:pt-0"
          >
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-300"
              enter-from="md:translate-x-full md:translate-y-0 translate-y-full"
              enter-to="md:translate-x-0 translate-y-0"
              leave="transform transition ease-in-out duration-300"
              leave-from="md:translate-x-0 translate-y-0"
              leave-to="md:translate-x-full translate-y-full md:translate-y-0 "
            >
              <DialogPanel
                class="pointer-events-auto relative w-screen md:max-w-md md:mt-0"
                :class="{ 'mt-52': mostrarModalPedidoPendiente }"
                :style="topValue"
              >
                <TransitionChild
                  as="template"
                  enter="ease-in-out duration-300"
                  enter-from="opacity-0"
                  enter-to="opacity-100"
                  leave="ease-in-out duration-300"
                  leave-from="opacity-100"
                  leave-to="opacity-0"
                  class="hidden md:block"
                >
                  <div
                    class="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 md:-ml-10 md:pr-4"
                  >
                    <button
                      ref="completeButtonRef"
                      type="button"
                      class="rounded-md text-gray-300 md:hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      @click="closeCart"
                    >
                      <span class="sr-only">Close panel</span>
                      <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </TransitionChild>
                <div
                  class="h-6 w-full rounded-t-xl bg-white py-2 md:hidden"
                  ref="swipe"
                >
                  <div class="mx-auto h-1.5 w-20 rounded bg-gray-100"></div>
                </div>
                <div class="flex h-full overflow-auto bg-white shadow-xl">
                  <div class="mb-2 flex-1 px-4 md:mb-2">
                    <div
                      v-if="loader"
                      class="h-full w-full flex items-center justify-center"
                    >
                      <Loader class="h-28 w-28" />
                    </div>
                    <Cart v-if="!loader" />
                    <ModalPedidoPendiente
                      v-if="mostrarModalPedidoPendiente"
                      @seguirComprando="mostrarModalPedidoPendiente = false"
                      @finalizarPago="
                        mostrarModalPagoPendiente = true;
                        mostrarModalPedidoPendiente = false;
                      "
                    />
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
  import {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
  } from "@headlessui/vue";
  import { XMarkIcon } from "@heroicons/vue/24/outline";
  import { useStore } from "@nanostores/vue";
  import {
    agregarItemsAlCarro,
    carroVisible,
    closeCart,
    openCart,
    clearCart,
  } from "@stores/carro";
  import { useSwipe } from "@vueuse/core";
  import Cart from "@components/carro/CarritoContainer.vue";
  import { ref, onMounted } from "@vue/runtime-core";
  import { user as userStore } from "@stores/user";
  import Loader from "@components/Loader.vue";
  import { getProductosPedido } from "@api/client/pedidos";
  import ModalPedidoPendiente from "@components/ModalPedidoPendiente.vue";
  interface Props {
    ruta: string;
  }
  const props = withDefaults(defineProps<Props>(), {
    ruta: "",
  });
  const loader = ref(true);
  const completeButtonRef = ref(null);
  const mostrarModalPedidoPendiente = ref(false);
  const mostrarModalPagoPendiente = ref(false);
  const rutasExcluidas = [
    "exito",
    "rechazo",
    "error-pago",
    "reintentar-pago",
    "checkout",
    "anulado",
  ];
  const rutasSeparadas = props.ruta.split("/");
  const CerrarCarro = () => {
    if (mostrarModalPedidoPendiente.value) return;
    closeCart();
  };

  onMounted(async () => {
    if (!rutasExcluidas.includes(rutasSeparadas[1])) {
      if (user.value && user.value.ultimo_pedido_pago_pendiente) {
        openCart();
        const { items } = await getProductosPedido(
          user.value.ultimo_pedido_pago_pendiente.id,
        );
        if (items) {
          clearCart();
          agregarItemsAlCarro(items);
        }
        mostrarModalPedidoPendiente.value = true;
      }
    }
    loader.value = false;
  });

  const swipe = ref(null);
  const topValue = ref("top: 0px");
  const user = useStore(userStore);
  const $carroVisible = useStore(carroVisible);
  const { lengthY } = useSwipe(swipe, {
    passive: false,
    threshold: 0,
    onSwipe(e) {
      let top = lengthY.value * -1;
      if (top < 0) {
        top = 0;
      }
      topValue.value = `top: ${top}px`;
    },
    onSwipeEnd(e, direction) {
      if (lengthY.value < -200) {
        closeCart();
        setTimeout(() => {
          topValue.value = "top: 0px";
        }, 500);
      } else {
        topValue.value = "top: 0px";
      }
    },
  });
</script>
