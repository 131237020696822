<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="isOpen = true" class="relative z-30">
      <div class="fixed inset-0 overflow-y-none">
        <div
          class="min-h-full mt-7 w-full md:w-auto md:p-4 text-center absolute md:right-[448px] md:top-1/3"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-md mx-auto transform overflow-hidden rounded-2xl bg-white p-2 sm:p-6 text-left align-middle shadow-xl transition-all"
            >
              <div
                class="flex h-fit flex-col justify-center gap-4 rounded-md bg-white p-4 text-center text-sm"
              >
                <span
                  >¡Hola! Tu pedido n°
                  {{ user?.ultimo_pedido_pago_pendiente?.id }} para el día
                  {{ fecha.dia + "/" + fecha.mes }}
                  de {{ fecha.hora }} a {{ fecha.minuto }} está
                  <span class="text-orange-400"> pendiente de pago </span>
                </span>

                <span>¿Quieres finalizar tu compra?</span>
                <div class="flex justify-center gap-x-3">
                  <button
                    @click="eliminarPedido"
                    class="rounded-md border border-gray-100 p-2"
                    aria-label="Seguir comprando"
                  >
                    Seguir comprando
                  </button>
                  <button
                    @click="finalizarPago"
                    aria-label="Finalizar pago"
                    class="rounded-md bg-green-500 p-2 text-white"
                  >
                    Finalizar pago
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
  import {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
  } from "@headlessui/vue";
  import { ref, computed } from "@vue/runtime-core";
  import {
    eliminarPedidoPendiente as eliminarPedidoPendienteAction,
    user as userStore,
  } from "@stores/user";
  import { eliminarPedidoPendiente as eliminarPedidoPendienteRequest } from "@api/client/users";
  import { useStore } from "@nanostores/vue";
  import { closeCart } from "@stores/carro";
  import dayjs from "dayjs";

  const emit = defineEmits(["seguirComprando", "finalizarPago"]);
  const isOpen = ref(true);
  const user = useStore(userStore);
  const mostrarMensaje = ref(false);
  const feedback = ref({
    estado: "",
    mensaje: "",
  });
  const mostrarModal = ref(false);

  const eliminarPedido = async () => {
    let pedido_id = null;
    let token = "";
    if (!user.value) return;
    if (user.value.ultimo_pedido_pago_pendiente) {
      pedido_id = user.value.ultimo_pedido_pago_pendiente.id;
      token = user.value.ultimo_pedido_pago_pendiente.token;
    }
    if (!pedido_id || !token) return;

    const respuesta = await eliminarPedidoPendienteRequest(pedido_id, token);
    feedback.value.estado = respuesta.estado;
    feedback.value.mensaje = respuesta.mensaje;

    if (respuesta.estado == "ok") {
      eliminarPedidoPendienteAction();
      mostrarMensaje.value = true;
    }
    setTimeout(() => {
      mostrarMensaje.value = false;
    }, 3000);
    mostrarModal.value = false;
    closeCart();
    emit("seguirComprando");
  };

  const finalizarPago = () => {
    if (!user.value) return;
    if (!user.value.ultimo_pedido_pago_pendiente) return;
    if (
      !user.value.ultimo_pedido_pago_pendiente.id &&
      !user.value.ultimo_pedido_pago_pendiente.token
    )
      return;
    window.location.href = `/reintentar-pago/${user.value.ultimo_pedido_pago_pendiente.id}/${user.value.ultimo_pedido_pago_pendiente.token}`;
  };

  const fecha = computed(() => {
    let formatos = {
      dia: "",
      mes: "",
      hora: "",
      minuto: "",
    };

    if (user.value && user.value.ultimo_pedido_pago_pendiente) {
      const fecha = dayjs(
        user.value.ultimo_pedido_pago_pendiente.fecha_despacho,
      );

      const horario = user.value.ultimo_pedido_pago_pendiente.ventana;
      formatos = {
        dia: fecha.format("DD"),
        mes: fecha.format("MM"),
        hora: String(horario.inicio).substring(0, 5),
        minuto: String(horario.termino).substring(0, 5),
      };
    }

    return formatos;
  });
</script>
