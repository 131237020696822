<template>
  <div>
    <picture>
      <source type="image/webp" srcset="/assets/frestar/estrella.webp" />
      <img
        src="/assets/frestar/estrella.png"
        alt="Estrella Frestar"
        class="w-full"
      />
    </picture>
  </div>
</template>
