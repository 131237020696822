<template>
  <div>
    <div class="flex items-center justify-between">
      <span class="text-xs text-green-700">{{ moneda(desde) }}</span>
      <div
        class="mx-2 h-1.5 w-10/12 rounded bg-gray-100"
        role="progressbar"
        :aria-valuenow="percentaje"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div
          class="h-1.5 rounded bg-green-700 text-center"
          :style="`width: ${percentaje}%; transition: width 2s;`"
        ></div>
      </div>
      <span class="text-xs text-green-700">{{ moneda(hasta) }}</span>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { moneda } from "@lib/formatters.js";
  import { computed } from "@vue/runtime-core";

  interface Props {
    desde: number;
    hasta: number;
    total: number;
  }

  const props = defineProps<Props>();

  const percentaje = computed(() => {
    if (props.hasta == 0) return 100;
    const porcentaje = (props.total / props.hasta) * 100;
    return porcentaje > 100 ? 100 : porcentaje;
  });
</script>
